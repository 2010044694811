
<template>
  <div>
    <el-form :inline="true" class="search">
      <el-form-item label="时间">
        <el-date-picker
            v-model="searchData.pay_at"
            type="daterange"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            value-format="YYYY-MM-DD"
        />
      </el-form-item>
      <el-form-item>
        <el-button @click="getData()">查询</el-button>
        <el-button @click="exportData()">导出</el-button>
      </el-form-item>
    </el-form>

    <el-divider>收支情况</el-divider>

    <el-row :gutter="16">
      <el-col :span="12" style="text-align: center;">
        <div class="statistic-card">
          <el-statistic :value="data.sum.in">
            <template #title>
              <div>收入 </div>
            </template>
          </el-statistic>
        </div>
      </el-col>
      <el-col :span="12" style="text-align: center;">
        <div class="statistic-card">
          <el-statistic :value="data.sum.out">
            <template #title>
              <div>支出</div>
            </template>
          </el-statistic>
        </div>
      </el-col>
    </el-row>

    <el-divider>统计视图</el-divider>

    <el-row :gutter="16">
      <el-col :span="24" :xs="24">
        <div id="budgetChart" style="width:100%;min-height:500px;"></div>
      </el-col>
      <el-col :span="24" :xs="24">
        <div id="costChart" style="width:100%;min-height:500px;"></div>
      </el-col>
      <el-col :span="24" :xs="24">
        <div id="personChart" style="width:100%;min-height:500px;"></div>
      </el-col>
    </el-row>

  </div>
</template>


<script>
import {apiGetProjectNumbersCount, apiExportProjectNumbers} from "@/api/projectNumbersApi"
import * as echarts from 'echarts'
import {getCompanyId} from "@/plugins/company"

export default {
  mounted() {
    this.getData()
  },
  created() {
    this.setDate()
  },
  data() {
    return {
      data: {
        sum: {
          out: 0,
          in: 0
        }
      },
      searchData: {
        pay_at: [],
        company_id: getCompanyId()
      },
    }
  },
  methods: {
    exportData() {
      window.open(apiExportProjectNumbers(this.searchData))
    },
    getData () {
      apiGetProjectNumbersCount(this.searchData).then(res => {
        this.data = res

        let budgetChart = []

        this.data.budgets.forEach((item) => {
          budgetChart.push({
            name: (item.project_budget_name ? item.project_budget_name : '无归属') +
                " " +
                item.total_price + " ("+ (item.total_price / res.sum.out * 100).toFixed(2) +"%)",
            value: item.total_price
          })
        })

        this.initChart('budgetChart', '预算类型TOP20', budgetChart)

        let costChart = []

        this.data.cost.forEach((item) => {
          costChart.push({
            name: item.remark + "(" +item.price + ")",
            value: item.price
          })
        })

        this.initChart('costChart', '单笔支出TOP20', costChart)

        let personChart = []

        this.data.person.forEach((item) => {
          personChart.push({
            name: item.person_name ? item.person_name + item.person_remark : '无归属' +
                " " +
                item.total_price + " ("+ (item.total_price / res.sum.out * 100).toFixed(2) +"%)",
            value: item.total_price
          })
        })

        this.initChart('personChart', '收款人TOP20', personChart)
      })
    },
    setDate () {
      // 获取当前日期
      const currentDate = new Date();
      // 将日期设置为本月的第一天
      const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      // 将日期设置为下个月的第一天，然后减去一天
      const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
      lastDay.setDate(lastDay.getDate() - 1);

      this.searchData.pay_at = [this.formatDateTime(firstDay), this.formatDateTime(lastDay)]
    },
    formatDateTime(date) {
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`
    },
    initChart(id, title, data = []) {
      let myChart = echarts.init(document.getElementById(id));

      let option = {
        title: {
          text: title,
          subtext: '实时数据',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '50%',
            data: data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      myChart.setOption(option);
    }
  }
}
</script>
